@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#tabs li{
  margin-left: 24px;
  margin-right: 24px;
}

#tabs button{
  margin-left: 24px;
  margin-right: 24px;
}


/* Custom scrollbar styles */
.scrollable-element::-webkit-scrollbar {
  width: 2px; /* Very thin scrollbar */
}

.scrollable-element::-webkit-scrollbar-track {
  background: #e0e0e0; /* Light background for the track */
}

.scrollable-element::-webkit-scrollbar-thumb {
  background-color: transparent; /* No thumb, just a subtle line */
  border-radius: 10px; /* Rounded corners */
}

/* For Firefox */
.scrollable-element {
  scrollbar-width: thin; /* Thin scrollbar */
  /* scrollbar-color: transparent #e0e0e0; Thumb is transparent, track is light gray */
}
.scrollable-element {
  height: calc(100vh - 288px);
  overflow: auto;
}

/* Medium screens (md) - max-width: 768px */
@media screen and (max-width: 768px) {
  .scrollable-element {
      height: calc(100vh - 272px);
  }
}

/* Extra large screens (xl) - min-width: 1280px */
@media screen and (min-width: 1280px) {
  .scrollable-element {
      height: calc(100vh - 288px);
  }
}


.status-active {
  border-color: #0A993A;
  background-color: #E6F7E9;
  color: #087D2F !important;
}

.status-inactive {
  border-color: #C53030;
  background-color: #FFF5F5;
  color: #bb0c0c !important;
}

.status-pending {
  border-color: #CBD5E0;
  background-color: #F5F5F5;
  color: #6B6B6B !important;
}

.status-unknown {
  border-color: #CBD5E0;
  background-color: #F5F5F5;
  color: #4A5568;
}

/* Generic styles to apply */
.status {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 4px 8px;
  width: 106px;
  height: 28px;
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  color: inherit;
}

.css-fyfizz{
  z-index:1 !important;
}

#Dialog{
  max-height: 100vh !important;
}

._1nx7rj94 ._57s5a90 {
  color: #000 !important
}

._1nx7rj94 {
  background-color: 'var(--z1zpiv1e)' !important
}
._1nx7rj96 {
  border: none !important;
  max-width: none !important

}
@media (max-width: 1300px) {
  #DialogNav {
      width: 40% !important;
  }
}

/* dont remove */
/* these done assuming it works in qa */
#wizard ._1m0z64zk {
  text-align: center;
  white-space: nowrap;
}


#wizard ._1m0z64zy {
  align-items: end;
}

#wizard ._1m0z64zu {
  width: 255px;
}

@media (max-width: 1375px) {
  #wizard ._1m0z64zu {
    width: 128px; /* Adjust width for mobile devices */
  }
}


@media (max-width: 1300px) {
  #wizard ._1m0z64zu {
    width: 90px; /* Adjust width for mobile devices */
  }
}

@media (max-width: 1160px) {
  #wizard ._1m0z64zu {
    width: 82px; /* Adjust width for mobile devices */
  }
}

@media (max-width: 1080px) {
  #wizard ._1m0z64zu {
    width: 70px; /* Adjust width for tablets */
  }
}

@media (max-width: 480px) {
  #wizard ._1m0z64zu {
    width: 150px; /* Adjust width for mobile devices */
  }
}

/* these styling works for local */
#wizard .wizard_centered__1m0z64zk {
  text-align: center;
  white-space: nowrap;
}

#wizard .wizard__1m0z64zy {
  align-items: end;
}


#wizard .wizard_horizontalCentered__1m0z64zu {
  width: 255px;
}

@media (max-width: 1375px) {
  #wizard .wizard_horizontalCentered__1m0z64zu {
    width: 128px; /* Adjust width for mobile devices */
  }
}

@media (max-width: 1300px) {
  #wizard .wizard_horizontalCentered__1m0z64zu {
    width: 90px; /* Adjust width for mobile devices */
  }
}

@media (max-width: 1160px) {
  #wizard .wizard_horizontalCentered__1m0z64zu {
    width: 82px; /* Adjust width for mobile devices */
  }
}

@media (max-width: 1080px) {
  #wizard .wizard_horizontalCentered__1m0z64zu {
    width: 70px; /* Adjust width for tablets */
  }
}

@media (max-width: 480px) {
  #wizard .wizard_horizontalCentered__1m0z64zu {
    width: 150px; /* Adjust width for mobile devices */
  }
}


/* these are duplicated for site wizard with  id=sitewizard  */
#sitewizard ._1m0z64zk {
  text-align: center;
  white-space: nowrap;
}


#sitewizard ._1m0z64zy {
  align-items: end;
}

#sitewizard ._1m0z64zu {
  width: 255px;
}

@media (max-width: 1375px) {
  #sitewizard ._1m0z64zu {
    width: 128px; /* Adjust width for mobile devices */
  }
}


@media (max-width: 1300px) {
  #sitewizard ._1m0z64zu {
    width: 90px; /* Adjust width for mobile devices */
  }
}

@media (max-width: 1160px) {
  #sitewizard ._1m0z64zu {
    width: 82px; /* Adjust width for mobile devices */
  }
}

@media (max-width: 1080px) {
  #sitewizard ._1m0z64zu {
    width: 70px; /* Adjust width for tablets */
  }
}

@media (max-width: 480px) {
  #sitewizard ._1m0z64zu {
    width: 150px; /* Adjust width for mobile devices */
  }
}

#sitewizard .wizard_centered__1m0z64zk {
  text-align: center;
  white-space: nowrap;
}

#sitewizard .wizard__1m0z64zy {
  align-items: end;
}


#sitewizard .wizard_horizontalCentered__1m0z64zu {
  width: 255px;
}

@media (max-width: 1375px) {
  #sitewizard .wizard_horizontalCentered__1m0z64zu {
    width: 128px; /* Adjust width for mobile devices */
  }
}

@media (max-width: 1300px) {
  #sitewizard .wizard_horizontalCentered__1m0z64zu {
    width: 90px; /* Adjust width for mobile devices */
  }
}

@media (max-width: 1160px) {
  #sitewizard .wizard_horizontalCentered__1m0z64zu {
    width: 82px; /* Adjust width for mobile devices */
  }
}

@media (max-width: 1080px) {
  #sitewizard .wizard_horizontalCentered__1m0z64zu {
    width: 70px; /* Adjust width for tablets */
  }
}

@media (max-width: 480px) {
  #sitewizard .wizard_horizontalCentered__1m0z64zu {
    width: 150px; /* Adjust width for mobile devices */
  }
}
/* these are duplicated for site wizard with  id=sitewizard  */

input[placeholder="Mobile number"] {
  padding-left: 120px;
}

input[placeholder="000 000 000"] {
  padding-left: 120px;
}

input[placeholder='View Mobile number']{
  padding-left: 90px
}

.App {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}


.crop-container {
  position: relative; /* No need for absolute positioning */
  width: 50%;
  height: 223px;
}
/* .reactEasyCrop_Contain{
      transform: translate(-2.3333px, -9.4583px) rotate(0deg) scale(0.333333)!important;
} */

/* dont remove */


/* Black Scrollbar */
.scroll-bar-black::-webkit-scrollbar {
  -webkit-appearance: none;
}

/* Vertical scrollbar (black) */
.scroll-bar-black::-webkit-scrollbar:vertical {
  width: 4px; /* Thickness of the scrollbar */
}

/* Horizontal scrollbar (black) */
.scroll-bar-black::-webkit-scrollbar:horizontal {
  height: 4px; /* Thickness of the scrollbar */
}

/* Scrollbar track (transparent background) */
.scroll-bar-black::-webkit-scrollbar-track {
  background-color: transparent; /* Keep the track transparent */
}

/* Scrollbar thumb (black bar with space) */
.scroll-bar-black::-webkit-scrollbar-thumb {
  background-color: #0072db; /* Black color for the thumb */
  border-radius: 22px;
  border: 4px solid transparent; /* Adding space by making the border transparent */
}

/* Optional: Slightly lighter thumb on hover */
.scroll-bar-black::-webkit-scrollbar-thumb:hover {
  background-color: #0072db; /* Lighter black on hover */
}


.hide-on-md {
  display: block; /* Default: Show */
}

@media screen and (max-width: 768px) { /* Medium screens */
  .hide-on-md {
      display: none; /* Hide */
  }
}

input[placeholder="Enter Level Name"] {
  padding-right: 46px !important;
}

input[placeholder='Give a label like Tower, Zone, Floor etc.'] {
  padding-right: 46px !important;
}
input[placeholder='Type Label'] {
  padding-right: 46px !important;
}

.base__oaqq2v2.base__oaqq2v5, 
.base__oaqq2v5:focus-visible:not(.base__oaqq2v3) {
    outline: none !important;
}

/* Scrollbar track */
.scrollable-table::-webkit-scrollbar {
  width: 10px;
  height: 11px;
  padding: 2px; /* Add some spacing around the scrollbar */

}

/* Scrollbar thumb (the moving part) */
.scrollable-table::-webkit-scrollbar-thumb {
  background-color: rgba(0, 67, 138, 0.18);
  border-radius: 4px;
  margin: 2px; /* Adds padding effect */
  border: 2px solid transparent; /* Creates padding effect */
  background-clip: padding-box;
}

/* Scrollbar thumb on hover */
.scrollable-table::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 67, 138, 0.18);
  
}

.scrollable-table::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 8px; /* Smooth edges */
  margin: 2px; /* Adds padding effect */
}

/* Scrollbar track (background) */
::-webkit-scrollbar {
  width: 10px;
  height: 11px;
  padding: 2px;}

/* Scrollbar handle */
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 67, 138, 0.18);
  border-radius: 4px;
  margin: 2px; /* Adds padding effect */
  border: 2px solid transparent; /* Creates padding effect */
  background-clip: padding-box;
}

/* #configDate .date-picker__5gx19ul .date-picker__5gx19uo ._5gx19ul ._5gx19uo ._5gx19ul ._5gx19up {
  width: 5rem;
} */



.search__country__input {
  position: relative !important;
  padding-left: 10px !important;

}

.search__country  svg {
  display: none !important;
}

/* Scrollbar handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 67, 138, 0.18);
}

/* Scrollbar track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 8px; /* Smooth edges */
  margin: 2px; /* Adds padding effect */
}
